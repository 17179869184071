/* texts styles */
.t1 {
    font: 20px 'proxima nova bold', sans-serif;
    color: #000;
}

.t2 {
    font: normal 20px 'proxima nova', sans-serif;
    padding-bottom: 15px;
    border-bottom: 1px solid #d6d6d6;
}

.t3 {
    font: 40px 'proxima nova bold', sans-serif;
    color: #000;
}

.t4 {
    font: normal 20px/1.5 'proxima nova', sans-serif;
}

.t5 {
    font: normal 20px/1.5 'museo slab bold', sans-serif;
    color: #748686;
}

.t6 {
    color: #748686;
}

.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-terms {
    line-height: 1.2;
}

/* colors */
.pale {
    color: #888;
}

.firm {
    color: #00b188;
}

/* misc */
.empty {
    color: #888;
}

.barcode-box {
    position: relative;
    display: inline-block;
    padding-left: 50px;
    max-width: 400px;
}

.parent-logo {
    position: absolute;
    left: -2px;
    top: 50%;
    margin-top: -40px;
}

.cashtie-terms {
    margin-top: 10px;
    font-size: 12px;
}

.separator1 {
    border-top: 2px dashed #aaa;
}

.mobile-separator {
    height: 1px;
    background-color: #ddd;
}

.table-box {
    display: table;
    width: 100%;
}

.table-box + .table-box {
    margin-top: 10px;
}

.flexible {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cell {
    padding-right: 10px;
}

.links-separator {
    display: inline-block;
    height: 10px;
    width: 1px;
    margin: 0 10px;
    background-color: #333;
}

.hidden{
    display: none;
}

.holder {
    position: relative;
}
