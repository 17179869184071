html {
    -webkit-text-size-adjust: none;
}

body {
    min-width: 280px;
}

.mobile-show {
    display: block !important;
}

.mobile-hide {
    display: none !important;
}

.mobile-wrapper {
    padding: 20px;
}

.strict {
    width: auto;
}

header {
    position: relative;
    padding: 0;
    margin-bottom: -2px;
    text-align: center;
}

header.locations-map {
    margin-bottom: 0;
}

footer {
    padding: 20px;
}

.popup {
    display: block;
    padding: 0;
}

.popup-inner {
    padding: 20px;
}

.main-content {
    padding-top: 0;
    padding-bottom: 310px;
    clear: both;
}

.content-box {
    padding: 0 0 40px;
    margin-bottom: 20px;
    border-top-width: 2px;
}

.subheader {
    height: 75px;
    position: static;
    padding: 0 20px;
    text-align: center;
}

.subheader-logo {
    margin-top: 15px;
}

.subheader-search {
    display: none;
    position: static;
    float: right;
    margin: 0 -20px 0 0;
}

.top {
    padding-top: 0;
    text-align: center;
}

.top li {
    display: none;
}

.top .logotype {
    display: none;
    width: 48px;
    margin-top: 0;
    right: 0;
    left: 10px;
    top: 65px;
}

.categories {
    width: 170px;
    text-align: center;
    margin-right: 0;
}

.categories span.label {
    display: block;
    height: 55px;
    line-height: 55px;
}

.menu-opened .categories span.label {
    background-color: #fff;
}

.categories span.icon.arrow {
    vertical-align: -3px;
}

.categories .list {
    position: absolute;
    top: 55px;
    left: -20px;
    right: -20px;
    width: auto;
    background-color: #fff;
}

.t1 {
    margin-bottom: 20px;
    font-size: 16px;
}

.t3 {
    font-size: 28px;
}

.t4 {
    font-size: 16px;
}

.t5 {
    font-size: 16px;
    margin-bottom: 0;
}

.settings-link {
    position: absolute !important;
    right: 20px;
    top: 20px;
    min-height: 0;
}

.unit-2of3,
.unit-1of3,
.unit-1of4,
.unit-1of2,
.unit-3of4,
.unit-right {
    float: none;
    width: auto;
    padding: 0;
    text-align: left;
    position: relative;
}

.right-column h1 {
    margin: 0;
    height: 59px;
    padding: 0 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid #ddd;
    border-left: 0;
    text-align: center;
    font: 16px/60px 'proxima nova bold', sans-serif;
}

.right-column h2 {
    border: 0;
    margin-bottom: 0;
    text-align: center;
}

/* auth */
.auth-box .unit-1of2,
.auth-box .pll,
.auth-box .prl,
.pass-change-box {
    float: none;
    width: auto;
    padding: 0;
}

/* Disabled due to CCA-431 bug in validation fields layout*/
/*.form div {*/
    /*padding: 0;*/
/*}*/

.help-tip.login{
    left: 50px;
}

.help-tip.biller-form{
    left: 180px;
}

.help-tip.confirmation {
    left: 0;
    position: relative;
    margin: 0 auto;
}

.help-tip p{    /* The tooltip */
    width: 130px;
    left: 40px;
    top: -10px;
}

.help-tip p:before{ /* The pointer of the tooltip */
    right: 170px;
    top: 10px;
}

.form .auth-box {
    padding: 20px;
}

.auth-box {
    width: 80%;
}

.auth-box .form-field-error-text {
    padding: 8px 14px;
    margin-top: 10px;
}

.auth-separator {
    position: static;
    width: auto;
    height: auto;
    border: 0;
    clear: both;
    border-top: 1px solid #d6d6d6;
}

.auth-separator span {
    height: auto;
    position: relative;
    left: 0;
    top: -9px;
    line-height: 1;
    margin: 0;
    padding: 0 20px;
}

.account-separator {display: none}

.auth-form-terms {
    width: auto;
}

.form-field input,
.form-field input[type="text"],
.form-field input[type="email"],
.form-field input[type="password"],
.select {
    padding: 14px;
    -webkit-appearance: none;
}

.form-field {
    margin-bottom: 10px;
}

.form-field label {
    margin: 10px 0 5px 0;
}

.btn-primary,
.btn-primary:visited,
.btn-secondary,
.btn-secondary:visited {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 18px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-appearance: none;
}

.btn-social {
    width: 50%;
    float: left;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.btn-social:first-child {
    padding-right: 10px;
}

.btn-social:last-child {
    padding-left: 10px;
}

.button.social,
input.social[type="button"] {
    height: 52px;
    background-size: 150px 150px;
}

.button.google,
input.google[type="button"] {
    background-position: 50% -59px;
}

.submit {
    margin-top: 20px;
}

.simple-list-item-options.submit {
    margin-top: 0;
}

.submit > .btn-primary,
.submit > .btn-secondary {
    margin: 10px 0;
}

.mobile-menu-link {
    width: 55px;
    height: 55px;
    position: absolute;
    left: 0;
    top: 0;
    background: #000 url(../../images/icon-menu-mobile.png) no-repeat 50% 50%;
    background-size: 30px 30px;
    -webkit-transform: translateX(0);
            transform: translateX(0);

    -webkit-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out;
}

.banners {
    margin-bottom: 0;
}

.banner {
    display: block;
    min-height: auto;
    background: #f7f8f8;
}

.banner.loggedin {
    display: none;
}

.signin-box {
    width: 280px;
    margin: auto;
}

.signin-box h2 {
    text-align: center;
}

.biller-categories .list .icon-category {
    width: 50px;
    height: 50px;
    display: table-cell;
    vertical-align: middle;
    background-size: 500px 50px;
}

.biller-categories h2,
.popular-billers h2 {
    text-align: center;
    font: 16px 'proxima nova bold', sans-serif;
    color: #abaaab;
}

.biller-categories h2 span {
    background: transparent;
}

.icon-category.my_bills {
    background-position: -350px 0;
}

.icon-category.popular {
    background-position: -300px 0;
}

.icon-category.financian {
    background-position: -250px 0;
}

.icon-category.wifi {
    background-position: -200px 0;
}

.icon-category.phones_home {
    background-position: -150px 0;
}

.icon-category.utilities {
    background-position: -100px 0;
}

.icon-category.cable_satellite {
    background-position: -50px 0;
}

/* categories */
.biller-categories .list {
    margin: 0 -20px;
}

.biller-categories .list li {
    display: block;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
}

.biller-categories .list h4 {
    display: table-cell;
    vertical-align: middle;
    height: 50px;
    padding: 0 10px;
    font-size: 17px;
}

.biller-categories .list li a {
    display: table;
    width: 100%;
    padding: 0 5px;
    text-align: left;
}

.popular-billers {
    margin: 0;
}

.popular-billers .btn-primary {
    display: inline-block;
    width: auto;
}

.popular-billers li,
.popular-billers li:last-child {
    display: block;
    width: auto;
    margin: 20px;
}

.popular-billers .list {
    display: block;
}

.biller-categories.my_bills {
    background-position: -350px 0;
}

.biller-categories.popular {
    background-position: -300px 0;
}

.biller-categories.financian {
    background-position: -250px 0;
}

.biller-categories.wifi {
    background-position: -200px 0;
}

.biller-categories.phones_home {
    background-position: -150px 0;
}

.biller-categories.utilities {
    background-position: -100px 0;
}

.biller-categories.cable_satellite {
    background-position: -50px 0;
}

.biller-categories.other {
    background-position: 0 0;
}

/* bilers */
.biller-group .simple-list-item img {
    height: 30px;
}

.biller-group {
    margin: 0 -20px;
    padding: 0 20px;
}

.biller-group-item-info {
    display: block;
    margin: 0 -30px;
    padding: 20px 30px;
}

.biller-group-cells {
    padding-right: 0;
    margin-bottom: 10px;
}

.biller-group-cells .list-info-item {
    display: block;
    width: auto;
}

.simple-list-item {
    padding: 0;
}

.simple-list-item h3 {
    font-size: 15px;
}

.back-link {
    display: inline-block;
    margin: 0;
    position: absolute;
    left: 12px;
    top: 15px;
    width: 32px;
    height: 32px;
    border: 1px solid #efeeee;
    border-radius: 50%;
}

.back-link::after {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    margin: 12px;
    border-left: 2px solid #00b188;
    border-top: 2px solid #00b188;
    color: #abaaab;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.mobile-text-center {
    text-align: center;
}

.breadcrumbs {
    margin-bottom: 20px;
    display: none;
}

.message {
    top: 0;
    left: 0;
    right: 0;
    width: auto;
    height: auto;
    margin: 0;
    text-align: left;
}

.message-inner {
    padding: 20px 60px;
    width: auto;
    height: auto;
    line-height: 1.3;
    font-size: 14px;
}

.message-close {
    top: 8px;
}

.message .message-inner {
    background-position: 15px 50%;
}

.info {
    margin: 20px 0;
}

.info-label {
    font-size: 12px;
}

.info-value {
    padding-top: 0;
    font-size: 22px;
    margin-bottom: 10px;
}

.table th,
.table td {
    padding: 10px 20px;
}

.table th {
    font-size: 16px;
    background-color: #f4f4f4;
}

.table td {
    font-size: 14px;
}

.verification-info {
    margin: 0 -20px -40px;
    padding: 20px 0;
    background-color: #f4f4f4;
}

.w300 {
    padding: 0;
}

.table-box {
    margin-bottom: 0;
}

.table-box .cell {
    padding: 0;
    display: block;
    width: auto;
    margin: 10px 0;
    text-align: left;
}

.ember-view {
    /*overflow: hidden;*/
}

.inline-info > span {
    display: block;
}

.inline-info > .inline-info-separator {
    display: none;
}

.app-message-inner {
    width: auto;
    left: 0;
    right: 0;
    margin-left: 0;
    margin-top: -110px;
    padding: 20px;
}

.app-message-inner p {
    font-size: 16px;
}

.app-message-inner img {
    width: 200px;
}

.account-list-item {
    padding: 20px 0;
}

.account-list-item .unit-1of4 {
    width: auto;
    float: none;
    padding-bottom: 10px;
}

.account-list-item .info-value {
    font-size: 18px;
}

.restore-pass .unit-1of2 {
    width: 100%;
    padding: 0;
    float: none;
}

.restore-pass .form-field {
    padding-right: 0;
}

.pass-change-box {
    margin-top: 10px;
}

.pass-change-box .form-field {
    padding-right: 0;
}

.empty {
    padding: 40px 20px 0;
    margin: 0;
    text-align: center;
}

.intro-text {
    padding: 10px 20px 0;
}

.outer-box {
    padding: 20px;
    margin-bottom: 20px;
    border-top-width: 2px;
    background: transparent;
}

.inner-box {
    border-width: 1px;
}

.biller-group .cell.biller-group-action {
    padding-right: 0;
}

.popular-billers {
    background-color: #fff;
}

.popular-billers li .thumb {
    height: auto;
}

.popular-billers li .thumb img,
.popular-billers li .thumb span {
    display: block;
    max-width: none;
    font-size: 16px;
}

.popular-billers li .thumb::before {
    display: none;
}

.popular-billers .inner-box,
.popular-billers .inner-box:last-child {
    padding: 20px;
    margin: 20px 0;
    background-color: #f7f8f8;
}

.right-column {
    margin-left: 0;
    padding-left: 0;
    border: 0;
}

.sidebar {
    width: auto;
    float: none;
    padding: 15px 0;
    border-bottom: 1px solid #ddd;
}

.sidebar-list {
    margin: 0;
    display: flex;
    justify-content: space-around;
}

.sidebar-list > li {
    margin: 0 3%;
}

.sidebar-list > li a {
    padding: 5px 0;
    min-height: auto !important;
}

.sidebar-list a.active {
    font-family: 'proxima nova bold', sans-serif;
    color: #4f4e4e;
}

.sidebar-list a.active::before {
    display: none;
}

.sidebar-header {
    display: none;
}

.load-more-container{
    padding: 0px 20px 0px 20px;
}

.biller-desc {
  float: none;
  display: block;
}

.search-box .clear-button {
    width: 54px;
    height: 54px;
    top: 0;
    right: 0;
    background: none;
    text-align: center;
    line-height: 56px;
}

.search-box .clear-button:after {
    content: '\d7';
    font-size: 34px;
    color: #777;
}

.zip-filter {
    padding-left:20px;
    margin-top:20px;
}

.retailers {
    flex-wrap: wrap;
}

.retailers li {
    width: 50%;
    padding: 15px 0;
}

.retailer-img-holder {
    display: block;
    width: auto;
    height: auto;
}

/* payment slip */
.breadcrumbs.for-mobile {
  display: block;
  padding: 20px 20px 0;
}

.payment-slip {
    padding: 20px;
    margin: 0;
}

.payment-slip-head {
    padding: 10px 20px;
    margin-bottom: 0;
    border-bottom: 1px solid #ddd;
}

.payment-slip-title {
    font-size: 26px;
}

.payment-slip-info,
.payment-slip-actions {
  padding: 20px;
  margin: 0;
}

.payment-slip-actions .unit-right {
  text-align: center;
}

.payment-slip-actions .cross-link {
  margin-top: 20px;
}

.payment-slip-info .print-link {
  display: none;
}

.payment-slip-info .unit {
  float: none;
}

.payment-slip-list-item {
  margin-bottom: 40px;
  padding-left: 0;
}

.payment-slip-list-item:last-child {
  padding-left: 0;
  margin-bottom: 0;
}

.payment-slip-list-item:last-child .number {
  display: none;
}

.payment-slip-list-item:last-child h4 {
  margin-left: 0;
}

.payment-slip-list-item:nth-child(5) {
  display: none;
}

.payment-slip-list-item h4 {
  margin-left: 35px;
  font-size: 18px;
}

.payment-slip-list-item .unit-1of3 {
  flex: 1;
  padding: 20px 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.payment-slip-list-item .payment-details {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  border: 1px solid #f1f1f1;
}

.payment-slip-list-item .number {
  width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
}

.payment-slip-list-item .amount-text {
  font-size: 24px;
}

.payment-slip-list-item .retailers li {
  padding: 10px;
  width: auto;
}

.payment-slip-header {
  display: flex;
  display: none;
  padding-top: 0;
}

.payment-slip-header > img {
  margin-right: 20px;
  height: 36px;
}

.is-highlighted {
  background-color: #f8f8f8;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.is-highlighted .barcode-box {
  max-width: 420px;
  padding-left: 42px;
  margin-left: -10px;
  margin-right: -20px;
  margin-bottom: 30px;
  background-position: 0 50%;
}

.is-highlighted .barcode-box .barcode {
    padding-bottom: 0;
}

.is-highlighted .barcode-number {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -30px;
    padding-left: 42px;
    text-align: center;
    font-size: 14px;
}

.is-highlighted .cashtie-terms {
  text-align: left;
}

@media screen and (max-width: 580px) {
    .main-content {
        padding-bottom: 0;
    }

    footer {
        position: static;
    }

    .ember-view.app-root,
    .ember-view.overlay {
        min-height: auto;
        position: static;
    }

    .sidebar-mobile-nav a.ember-view {
        position: relative !important;
    }
}

.locations-map .mobile-search-link {
    display: none !important;
}

.locations-map .logotype {
    display: none;
}

.speedway-logo {
    min-width: 76px;
}

.speedway-bar-text {
    font-size: 14px;
    margin-left: 15px;
    max-width: none;
}

.speedway-holder + .category-title,
.heb-holder + .category-title {
    position: relative;
}

.speedway-holder,
.heb-holder {
    margin: 8px 8px 0;
    text-align: center;
}

.nomobile {
    display: none;
}

.general-ereceipt-footer{
    width: auto;
    display: block;
    text-align: center;
    
}

.general-ereceipt-search-button {
    width: 300px;
    display: inline-block;
}

.general-ereceipt-copyright {
    margin-top: 10px;
}

.general-ereceipt-header {
    height: 60px;
}

.general-ereceipt-logo {
    height: 60px;
    background-size: auto 40px;
}

.show-for-mobile {
    display: block;
}

.hide-for-mobile {
    display: none;
}

.general-ereceipt-mobile-wrapper {
    width: auto;
    padding: 0 20px 20px;
}

.address-list li{
    flex-direction: column;
    padding: 10px 20px;
    align-items: flex-start;
}

.address-list li .btn-primary {
    width: auto;
    margin-top: 10px;
}
