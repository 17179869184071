.signin-box {
    width: 320px;
    background-color: #fff;
    border-top: 5px solid #c6c4c3;
    padding: 20px;
    text-align: left;
    color: #4e4e4e;
}

.signin-box h2 {
    font: 13px 'museo slab bold', sans-serif;
}

.signin-box .form-field {
    margin: 20px;
    padding-right: 0;
}

.signin-box .form-field label {
    margin: 5px 0;
}

.signin-box .form-submit {
    margin: 30px 20px 20px;
}

.signin-box-links {
    text-align: center;
}

.signin-box-links a {
    margin: 0 6px;
    font-family: 'proxima nova', sans-serif;
}