.popup {
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    padding: 40px 0;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    -webkit-overflow-scrolling: touch;
}

.popup-inner {
    padding: 40px;
    background-color: #fff;
}

.popup .form-field {
    padding-right: 0;
}
.popup-close {
  font: 42px/1 'proxima nova', sans-serif;
  text-decoration: none;
  position: absolute;
  top: 20px;
  right: 40px;
}

.popup-inner {
  position: relative;
}
.popup-close:hover {
  text-decoration: none;
}
