cite, em, address {
    font-style: normal;
}

img {
    -moz-user-select: none;
    -webkit-user-select: none;
}

input::-moz-focus-inner { 
    border: 0;
    padding: 0;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
    display: block;
}

a {
    outline: none;
}

/* grids */
.outer:before,
.outer:after{content:"";display:table;}

.outer {*zoom:1;}
.outer:after{clear:both;}

.unit,
.unit-1of2,
.unit-1of3,
.unit-2of3,
.unit-1of4,
.unit-3of4,
.unit-1of5,
.unit-1of10,
.unit-2of5,
.unit-3of5,
.unit-4of5,
.unit-5of5 {
    float: left;
    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
    box-sizing: border-box;
}

.unit-1of2{width:50%;}
.unit-1of3{width:33.33333%;}
.unit-2of3{width:66.66666%;}
.unit-1of4{width:25%;}
.unit-3of4{width:75%;}
.unit-1of5{width:20%;}
.unit-1of10{width:10%;}
.unit-2of5{width:40%;}
.unit-3of5{width:60%;}
.unit-4of5{width:80%;}
.unit-5of5{width:100%;}

.unit-1of1{float:none;}
.unit-right{float:right;}
.unit-last{float:none;display:block;display:table-cell;width:9999em;*width:auto;*zoom:1;_position:relative;_left:-3px;_margin-right:-3px;}
.w300 {width: 300px;}

.unit-1of3:nth-child(3n+1) {
    clear: left;
}

/* spaces */
.ptn,.pvn,.pan{padding-top:0px}
.pts,.pvs,.pas{padding-top:10px}
.ptm,.pvm,.pam{padding-top:20px}
.ptl,.pvl,.pal{padding-top:40px}
.prn,.phn,.pan{padding-right:0px}
.prs,.phs,.pas{padding-right:10px}
.prm,.phm,.pam{padding-right:20px}
.prl,.phl,.pal{padding-right:40px}
.pbn,.pvn,.pan{padding-bottom:0px}
.pbs,.pvs,.pas{padding-bottom:10px}
.pbm,.pvm,.pam{padding-bottom:20px}
.pbl,.pvl,.pal{padding-bottom:40px}
.pln,.phn,.pan{padding-left:0px}
.pls,.phs,.pas{padding-left:10px}
.plm,.phm,.pam{padding-left:20px}
.pll,.phl,.pal{padding-left:40px}
.mtn,.mvn,.man{margin-top:0px}
.mts,.mvs,.mas{margin-top:10px}
.mtm,.mvm,.mam{margin-top:20px}
.mtl,.mvl,.mal{margin-top:40px}
.mrn,.mhn,.man{margin-right:0px}
.mrs,.mhs,.mas{margin-right:10px}
.mrm,.mhm,.mam{margin-right:20px}
.mrl,.mhl,.mal{margin-right:40px}
.mbn,.mvn,.man{margin-bottom:0px}
.mbs,.mvs,.mas{margin-bottom:10px}
.mbm,.mvm,.mam{margin-bottom:20px}
.mbl,.mvl,.mal{margin-bottom:40px}
.mln,.mhn,.man{margin-left:0px}
.mls,.mhs,.mas{margin-left:10px}
.mlm,.mhm,.mam{margin-left:20px}
.mll,.mhl,.mal{margin-left:40px}

.txt-center {
    text-align: center;
}
.txt-right {
    text-align: right;
}

.txt-left {
    text-align: left;
}
