.outer-box {
	padding: 20px 20px 0;
	margin-bottom: 55px;
	background-color: #fff;
	border-top: 5px solid #03af88;
}

.inner-box {
	padding: 20px;
	background-color: #f7f8f8;
	border-top: 5px solid #d4d4d4;
}

.content-box {
	margin-bottom: 55px;
	padding: 30px 40px 40px;
	background-color: #fff;
	border-top: 3px solid #706f74;
}

.alert-box {
	background-color: #d3cdcc;
	padding: 10px;
	border-radius: 10px;
	color: black;
}

.alert-box h2 {
	color: black;

}

.alert-box p {
	margin-top: 10px;
}
