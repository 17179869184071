.pih-map-wrapper {
    overflow: hidden;
}

.pih-map-wrapper iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}
