.otc-balance {
    font-size: large;
    font-weight: bold;
    margin-left: 5px;
}

.line {
    display: flex;
    justify-items: center;
}
.line span {
    margin: auto 5px auto auto;
}
