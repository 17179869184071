.sidebar-mobile {
    width: 266px;
    position: fixed;
    z-index: 8;
    left: 0;
    top: 0;
    bottom: 0;
    overflow: auto;
    background-color: #fafafa;
    -webkit-overflow-scrolling: touch;
    
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);

    -webkit-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out;
}

.sidebar-opened .sidebar-mobile {
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

.sidebar-mobile-userbar {
    padding: 20px 0;
    margin: 20px 30px 0;
    font: 14px 'proxima nova bold', sans-serif;
}

.sidebar-mobile-user-name {
    display: block;
    margin-top: 5px;
    font: 18px 'proxima nova', sans-serif;
    color: #8e8e8e;
}

.sidebar-mobile-user-name a {
    font: 18px 'proxima nova', sans-serif;
    color: #8e8e8e;
}

.sidebar-mobile-nav {
    padding: 0 30px 20px;
}

.sidebar-mobile-links {
    margin: 0 20px;
    padding-top: 20px;
    padding-bottom: 40px;
    border-top: 1px solid #dbdbdb;
}

.sidebar-mobile-nav a {
    display: block;
    position: relative;
    padding: 5px 16px;
    font-family: 'proxima nova', sans-serif;
}

.sidebar-mobile-nav a.active {
    font-family: 'proxima nova bold', sans-serif;
    color: #333;
}

.sidebar-mobile-nav a:hover {
    text-decoration: none;
}

.sidebar-mobile-nav a:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #4f4e4e;
    position: absolute;
    left: 0;
    top: 13px;
}

.sidebar-mobile-search {
    margin-top: 2px;
    display: none;
}

.sidebar-mobile-search input[type="search"] {
    border: 0;
    background: #f4f4f4 url(../../images/icon-mobile-search.png) no-repeat 18px 50%;
    background-size: 17px 17px;
    border-radius: 0;
    width: 100%;
    border: 0;
    padding: 18px 52px;
    font-size: 15px;
    -webkit-appearance: none;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #777;
}

.sidebar-mobile-search .search-box {
    display: block;
    margin: 0;
    border-bottom: 2px solid #00b188;
    -webkit-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out;
}

.sidebar-mobile-search .clear-button{
    top: 10px;
    right: 0;
}

.sidebar-opened {
    overflow: hidden;
}

.sidebar-opened .main-content,
.sidebar-opened header .mobile-menu-link,
.sidebar-opened header ul.top li.logotype,
.sidebar-opened footer,
.sidebar-opened .subheader,
.sidebar-opened .sidebar-mobile-search .search-box,
.sidebar-opened .header-logo,
.sidebar-opened .mobile-search-link {
    -webkit-transform: translateX(266px);
            transform: translateX(266px);
}

.menu-overlay {
    display: none;
    position: fixed;
    z-index: 7;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0, 0.6);
}

.sidebar-opened .menu-overlay {
    display: block;
}

