.table {
    width: 100%;
}

.table th {
    font-size: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #d6d6d6;
}

.table td {
    padding: 10px 0;
    border-bottom: 1px solid #d6d6d6;
}