.btn-primary,
.btn-primary:visited {
    border: 0;
    display: inline-block;
    height: 48px;
    padding: 0 40px;
    font: normal 20px/48px 'museo slab bold', sans-serif;
    background-color: #4f4e4e;
    color: #fff;
    cursor: pointer;
    text-align: center;
    transition: opacity .3s ease;
    box-shadow: 2px 3px 0 0 #c0bfbe;
    border-radius: 0;
    white-space: nowrap;
    min-height: auto !important;
}

.btn-primary:hover {
    background-color: #9b989a;
    color: #4f4e4e;
    text-decoration: none;
}

.btn-secondary,
.btn-secondary:visited {
    border: 0;
    background-color: transparent;
    color: #ef5f0b;
    padding: 0;
    border-radius: 0;
    cursor: pointer;
    font: normal 20px/48px 'museo slab bold', sans-serif;
    white-space: nowrap;
    min-height: auto !important;
}

.btn-secondary:hover {
    color: #ef5f0b;
    text-decoration: none;
}

.btn-primary.small {
    font-size: 15px;
    padding: 0 30px;
    height: 38px;
    line-height: 38px;
}

.btn-primary.full-width {
    width: 100%;
}

.btn-submit-hidden {
    position: absolute;
    left: -9999px;
}
