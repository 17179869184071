/* Reset styles */
@import "reset.css";

/* grids, spaces */
@import "base.css";

/* layouts */
@import "layout.css";

/* components */
@import "components/headers.css";
@import "components/links.css";
@import "components/helpers.css";
@import "components/forms.css";
@import "components/buttons.css";
@import "components/tables.css";
@import "components/messages.css";
@import "components/lists.css";
@import "components/icons.css";
@import "components/infos.css";
@import "components/panels.css";
@import "components/amount-page.css";

/* modules */
@import "modules/popular-billers.css";
@import "modules/biller-categories.css";
@import "modules/biller-groups.css";
@import "modules/auth.css";
@import "modules/signin-box.css";
@import "modules/popup.css";
@import "modules/mobile-sidebar.css";
@import "modules/ereceipt.css";
@import "modules/locations.css";
@import "modules/address-list.css";
@import "modules/setup-new-biller.css";

/* Extra Small Devices, Phones */
@import url('mobile/mobile.css') only screen and (max-width: 960px);

/* Small Devices, Tablets */
/*@import url('tablet/tablet.css') only screen and (min-width: 481px) and (max-width: 1023px);*/

/* Medium Devices, Desktops */
@import url('desktop/desktop.css') only screen and (min-width: 961px);

@media only screen and (min-width: 480px) {
    .device-mode-message {
        display: none;
    }
}
