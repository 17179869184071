
input {
    outline: none;
    font: 13px 'proxima nova bold', sans-serif;
}

.form-field {
    padding-right: 56px;
    position: relative;
}

.form-field.centered {
    padding-right: 0;
    max-width: 300px;
    margin: auto;
}

.form-field input,
.form-field input[type="text"],
.form-field input[type="email"],
.form-field input[type="password"],
.select,
.form-field textarea {
    width: 100%;
    padding: 14px 12px;
    border: 1px solid #c5c5c6;
    background-color: #f8f8f8;
    border-bottom: 1px solid rgba(0,0,0, 0.1);
    border-radius: 0;
    box-sizing: border-box;
    transition: all .5s ease;
    box-shadow: none;
}

input[type="text"], input[type="email"], input[type="password"], textarea {
    outline: none;
    font: 13px 'proxima nova bold', sans-serif;
    -webkit-appearance: none;
}

.form-field input:focus,
.form-field input[type="text"]:focus,
.form-field input[type="email"]:focus,
.form-field input[type="password"]:focus,
.select:focus,
.form-field textarea:focus {
    box-shadow: 0 0 4px rgba(0,0,0, 0.2);
    border-color: #aaa9aa;
    outline: none;
}

.form-field textarea {
    min-height: 120px;
}

.form-field label {
    display: block;
    margin: 5px 0;
    color: #748686;
}

.form-field-error:after {
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    position: absolute;
    right: 24px;
    top: 62px;
    background: url("../../images/icon-error.png") no-repeat 0 0;
}

.form-field-error-text {
    padding: 5px 12px;
    margin-top: 5px;
    border: 1px solid #d0202f;
    color: #d0202f;
    background-color: transparent;
    border-radius: 0;
    text-align: left;
    font-size: 13px;
}

.form-field-error-text b{
    color: #d0202f;
    font-weight: bold;
}


/*custom select. class applies to select element itself, not a wrapper element */
.select-css {
    display: block;
    font-size: 16px;
    font-family: sans-serif;
    font-weight: 700;
    color: #444;
    line-height: 1.3;
    padding: .6em 1.4em .5em .8em;
    width: 100%;
    max-width: 100%; /* useful when width is set to anything other than 100% */
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background: url(../../images/arrow-list.svg) no-repeat 100% 50%;
    background-repeat: no-repeat, repeat;
    /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
    background-position: right .7em top 50%, 0 0;
    /* icon size, then gradient */
    background-size: .9em auto, 100%;
}
/* Hide arrow icon in IE browsers */
.select-css::-ms-expand {
    display: none;
}
/* Hover style */
.select-css:hover {
    border-color: #888;
}
/* Focus style */
.select-css:focus {
    border-color: #aaa;
    /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
    box-shadow: 0 0 1px 1px rgb(253, 93, 0);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
}

/* Set options to normal weight */
.select-css option {
    font-weight:normal;
}

/* Disabled styles */
.select-css:disabled, .select-css[aria-disabled=true] {
    color: graytext;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
}

.select-css:disabled:hover, .select-css[aria-disabled=true] {
    border-color: #aaa;
}

/* custom radio button */
.radio-button {
    display: inline-block;
    width: 32px;
    height: 32px;
    position: relative;
    cursor: pointer;
    border: 2px solid #414141;
    background-color: #fff;
    border-radius: 50%;
}

.radio-button-state {
    width: 28px;
    height: 28px;
    position: absolute;
    left: 2px;
    top: 2px;
    border-radius: 50%;
    transition: all 0.2s ease-out;
    transform: scale(0);
}

.radio-button > input {
    opacity: 0;
    margin: 0;
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.radio-button > input:checked + .radio-button-state {
    background-color: #414141;
    transform: scale(1);
}


/* custom checkbox */
.checkbox {
    display: inline-block;
    height: 34px;
    position: relative;
    cursor: pointer;
}

.checkbox > input {
    width: 34px;
    height: 34px;
    margin: 0;
    opacity: 0;
    position: absolute !important;
    left: 0;
    top: 0;
}

.checkbox > input:checked + .checkbox-state:before {
    background-position: 0 -35px;
}

.checkbox-state {
    display: inline-block;
    height: 34px;
    line-height: 34px;
    position: relative;
    padding-left: 54px;
}

.checkbox-state:before {
    content: '';
    display: block;
    width: 34px;
    height: 34px;
    position: absolute;
    left: 0;
    top: 0;
    background: url(../../images/checkbox.png) no-repeat 0 0;
}

/* Search Box */
.search-box{
    position: relative;
    vertical-align: top;
    display: inline-block;
}

.search-box .clear-button {
    position: absolute;
    top:2px;
    right:2px;
    width: 32px;
    height: 32px;
    background: url(../../images/icons.png) 0 -87px no-repeat;
}
