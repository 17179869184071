/* error/notice messages */
.message {
    height: 64px;
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    right: 0;
    font: 16px 'proxima nova', sans-serif;
    color: #fff;
}

.message-inner {
    position: relative;
    width: 940px;
    height: 64px;
    line-height: 1.3;
    margin: auto;
    padding: 0 60px;
    display: flex;
    align-items: center;
    vertical-align: middle;
    box-sizing: border-box;
}

.message-close {
    font: 40px/40px "proxima nova", sans-serif;
    color: #fff !important;
    position: absolute;
    width: 40px;
    height: 40px;
    text-align: center;
    right: 10px;
    top: 11px;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity 0.3s ease;
}

.message-close:hover {
    text-decoration: none;
    opacity: 1;
}

.message.error {
    background-color: #d0202e;
}

.message.notice {
    background-color: #00b088;
}

.message.notice .message-inner {
    background: url(../../images/icon-notice.png) 15px 50% no-repeat;
}

.message.error .message-inner {
    background: url(../../images/icon-error.png) 15px 50% no-repeat;
}

/* loading messages */
@keyframes loading {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
}

@-moz-keyframes loading {
    0% {-moz-transform: rotate(0deg);}
    100% {-moz-transform: rotate(360deg);}
}

@-webkit-keyframes loading {
    0% {-webkit-transform: rotate(0deg);}
    100% {-webkit-transform: rotate(360deg);}
}

@-o-keyframes loading {
    0% {-o-transform: rotate(0deg);}
    100% {-o-transform: rotate(360deg);}
}

#spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 10;
    font-size: 14px;
}

#spinner::before {
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -70px 0 0 -65px;
    content: '';
    z-index: 10;
    width: 130px;
    height: 36px;
    /*background: url(/images/vanilla-logo.png) no-repeat 0 0;*/
    background-size: contain;
    opacity: 80%;
}

#spinner::after {
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -15px 0 0 -15px;
    content: '';
    z-index: 10;
    cursor: wait;
    border-radius: 50px;
    animation: loading .5s infinite linear;
    -moz-animation: loading .5s infinite linear;
    -webkit-animation: loading .5s infinite linear;
    -o-animation: loading .5s infinite linear;
    border: 5px solid #fff;
    border-top: 5px solid #ef5f0b;
    border-left: 5px solid #ef5f0b;
    width: 30px;
    height: 30px;
}

#loader {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 9;
    opacity: 0.7;
    display: block;
}

#loader::after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: '';
    z-index: 10;
    cursor: wait;
    border-radius: 50px;
    margin: -15px 0 0 -15px;
    animation: loading .5s infinite linear;
    -moz-animation: loading .5s infinite linear;
    -webkit-animation: loading .5s infinite linear;
    -o-animation: loading .5s infinite linear;
    border: 5px solid rgba(255, 255, 255, 0.7);
    border-top: 5px solid rgba(0, 0, 0, 0.7);
    border-left: 5px solid rgba(0, 0, 0, 0.7);
    width: 30px;
    height: 30px;
}

/* no js message */
.app-message {
    display: block;
    position: fixed;
    z-index: 50;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    background-color: #fff;
    color: #414141;
}


.app-message-inner {
    width: 400px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -90px 0 0 -200px;
}

.app-message-inner img {
    width: 188px;
}

.app-message-inner p {
    font-size: 20px;
    margin-top: 40px;
}

h1.error-color, .error-color {
    color: red;
}

.hidden-color {
    color: white;
}

.shake {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}
