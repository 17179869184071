.purse-row .balance {
    float: right
}

.purse-row {
    margin: 5px 0;
    width: 350px;
}

.eligible-tag {
    padding: 3px 5px;
    border-radius: 2px;
    margin-left: 5px;
}

.eligible-tag.eligible {
    background-color: #6bbe1e;
    color: white;
}

.eligible-tag.not-eligible {
    background-color: #fd5d00;
    color: white;
}
