.simple-list-item {
    border-bottom: 2px solid #efeeee;
}

.simple-list-item:last-child {
    border-bottom: 0;
}

.simple-list-item ul {
    display: none;
}

.simple-list-item.expanded {
    margin-bottom: 2px;
    background-color: #efeeee;
}

.simple-list-item.expanded ul {
    display: block;
    padding: 0 30px 20px;
}

.simple-list-item.expanded .expand-link:after {
    background-position: 0 -24px;
}

.simple-list-item .clickable {
    cursor: pointer;
}

.simple-list-item-options {
    padding-bottom: 20px;
}

.account-list-item {
    padding: 30px 0;
    border-bottom: 1px solid #dbdbdb;
}

.retailers {
    display: flex;
}

.retailers li {
    display: inline-block;
    vertical-align: top;
    width: 32.5%;
    box-sizing: border-box;
    text-align: center;
}

.retailer-img-holder {
    display: table-cell;
    width: 500px;
    vertical-align: middle;
    height: 60px;
}

.retailer-new-img-holder {
    padding-left: 25px;
}

.retailers .payment-logo {
    height: 42px;
}

.cvs-logo {
    height: 60px;
}


/* info list */
.info-list {
    clear: both;
    padding: 20px;
}

.info-list:nth-child(even) {
    background-color: #f6f6f6;
}

.info-list-item {
    overflow: hidden;
    padding: 8px 0;
    border-top: 1px solid #eee;
}

.info-list-item:first-child {
     border-top: 0;
     padding-top: 0;
}

.info-list-label {
    width: 50%;
    float: left;
    color: #696969;
    font-size: 11px;
    text-transform: uppercase;
}

.info-list-value {
    width: 50%;
    float: left;
    text-align: right;
}

.cutter {
    width: auto;
}

.payment-list li {
    border-bottom: 1px solid #fd5d00;
    margin-bottom: 10px;
}
.payment-list li:last-child {
    border-bottom: none;
    margin-bottom: 0;                                                                            
}

.payment-list li div.label {
    width: 150px;
    display: inline-block;
}
.payment-list li div.value {
    display: inline-block;
    font-weight: bold;
}

.level2 {
    margin-left: 40px;
}

ul.level2 > li {
    list-style-type: disc;
}
