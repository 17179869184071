.biller-categories h2 {
    font: 13px 'museo slab bold', sans-serif;
    margin: 0 0 20px;
    border: 0;
    color: #4f4e4e;
}

.biller-categories h2 span {
    text-transform: uppercase;
    display: inline-block;
    background: #fff;
    text-transform: none;
}

.biller-categories .list {
    list-style-type: none;
    margin-right: -22px;
}

.biller-categories .list li {
    vertical-align: top;
    display: inline-block;
    text-align: center;
    width: 164px;
    height: 120px;
    margin: 0 18px 30px 0;
}

.biller-categories .list li a {
    display: inline-block;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.biller-categories .list li a:hover {
    text-decoration: none;
    opacity: 0.6;
}

.biller-categories .icon-category {
    border: 0;
    margin-top: -25px;
    margin-bottom: -10px;
}

.biller-categories .list li a:hover .icon-category {
    border-color: #555;
}

.biller-categories .list h4 {
    padding: 0;
    color: #4f4e4e;
}
