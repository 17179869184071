.info-label {
    font-size: 13px;
    text-transform: uppercase;
}

.info-value {
    padding: 10px 0;
    font-size: 24px;
    color: #000;
}

.inline-info {
    font-size: 13px;
    color: #888;
}

.inline-info-separator {
    margin: 0 10px;
}
