.biller-group .cell.biller-group-fee,
.biller-group .cell.biller-group-posting-time,
.biller-group .cell.biller-group-state {
    width: 25%;
}

.biller-group .cell.biller-group-action {
    width: 40%;
}

.biller-group h3 {
    font: 16px/1.1 'museo slab bold', sans-serif;
}

.biller-group-item-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
}

.biller-group-item-info:last-child {
    border-bottom: 0;
    padding-bottom: 0;
}

.biller-group-cells {
    flex: 1 1 auto;
    padding-right: 20px;
}

.biller-group-cells .list-info-item {
    margin: 3px 0;
}

.biller-group-cells .list-info-item.no-width {
    width: auto;
}

.biller-group-item-info .cell {
    vertical-align: top;
}
.biller-desc {
    float: right;
    font: 14px proxima nova;
    color: rgb(153, 153, 153);
}
.simple-list-item.expanded .biller-desc {
    display: none;
}

.simple-list-item.expanded .expand-link {
    color: #4f4e4e;
}

.simple-list-item.expanded .expand-link::after {
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    left: 11px;
    margin-top: -7px;
}
