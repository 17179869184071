.location-link {
    display: inline-block;
    padding: 5px 0 5px 30px;
    font: 16px 'proxima nova bold', sans-serif;
    background: url(../../images/icon-location.png) no-repeat 0 50%;
    color: #00b188;
}

.print-link:hover,
.location-link:hover,
.cross-link:hover,
.cancel-link:hover,
.email-link:hover {
    color: #00b188;
}

.home-link {
    padding-left: 20px;
    background: url(../../images/icon-home.png) no-repeat 0 50%;
    background-size: 13px 11px;
    color: #414141;
}

.back-link {
    display: none;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    margin: 0 10px 0 -14px;
}

.expand-link {
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    color: #ef5f0b;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.expand-link:hover {
    text-decoration: none;
    opacity: 0.7;
}

.expand-link:after {
    content: '';
    display: block;
    position: absolute;
    left: 8px;
    top: 50%;
    margin-top: -4px;
    width: 6px;
    height: 6px;
    border-left: 2px solid;
    border-top: 2px solid;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
}
