.e-receipt {
    padding: 20px;
    border: 1px solid #888;
    font-size: 16px;
}

.e-receipt-line {
    height: 0;
    margin: 20px -20px;
    border-top: 1px solid #888;
}

.e-receipt table tr {
    padding:10px 0;
}
