.icon-category.transportation {
    background-position: -900px 0;
}

.icon-category.phones-wireless {
    background-position: -800px 0;
}

.icon-category.my_bills {
    background-position: -700px 0;
}

.icon-category.popular {
    background-position: -600px 0;
}

.icon-category.financian {
    background-position: -500px 0;
}

.icon-category.wifi {
    background-position: -400px 0;
}

.icon-category.phones_home {
    background-position: -300px 0;
}

.icon-category.utilities {
    background-position: -200px 0;
}

.icon-category.cable_satellite {
    background-position: -100px 0;
}

.icon-category.other {
    background-position: 0 0;
}

/* small category icons */
.icon-category.small {
    background-size: 500px 50px;
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 0;
}

.icon-category.small.my_bills {
    background-position: -350px 0;
}

.icon-category.small.popular {
    background-position: -300px 0;
}

.icon-category.small.financian {
    background-position: -250px 0;
}

.icon-category.small.wifi {
    background-position: -200px 0;
}

.icon-category.small.phones_home {
    background-position: -150px 0;
}

.icon-category.small.utilities {
    background-position: -100px 0;
}

.icon-category.small.cable_satellite {
    background-position: -50px 0;
}

.icon-category.small.other {
    background-position: 0 0;
}
