.setup-new-biller-block h4 {
    text-decoration: underline;
    margin-bottom: 10px;
    margin-top: 20px;
}

.setup-new-biller-block td:nth-child(2) {
    min-width: 100px;
}

.setup-new-biller-block .bold {
    font-weight: bold;
}

.setup-new-biller-block .disclaimer {
    color: silver;
}

.setup-new-biller-block li {
    list-style-type: circle;
}

.setup-new-biller-block .disclaimer a {
    color: silver;
}

.setup-new-biller-block td {
    padding-bottom: 10px;
}

h2 {
    margin-bottom: 10px;
}
