@font-face {
    font-family: 'proxima nova';
    src: url('../fonts/proxima_nova_alt_reg-webfont.eot');
    src: url('../fonts/proxima_nova_alt_reg-webfont.eot?#iefix') format("embedded-opentype"),
         url('../fonts/proxima_nova_alt_reg-webfont.woff') format("woff"),
         url('../fonts/proxima_nova_alt_reg-webfont.ttf') format("truetype"),
         url('../fonts/proxima_nova_alt_reg-webfont.svg#proxima_nova_rgregular') format("svg");
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'proxima nova bold';
    src: url('../fonts/proxima_nova_alt_sbold-webfont.eot');
    src: url('../fonts/proxima_nova_alt_sbold-webfont.eot?#iefix') format("embedded-opentype"),
         url('../fonts/proxima_nova_alt_sbold-webfont.woff') format("woff"),
         url('../fonts/proxima_nova_alt_sbold-webfont.ttf') format("truetype"),
         url('../fonts/proxima_nova_alt_sbold-webfont.svg#proxima_nova_rgbold') format("svg");
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'museo slab';
    src: url('../fonts/27BDE8_0_0.eot');
    src: url('../fonts/27BDE8_0_0.eot?#iefix') format("embedded-opentype"),
         url('../fonts/27BDE8_0_0.woff') format("woff"),
         url('../fonts/27BDE8_0_0.ttf') format("truetype");
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'museo slab bold';
    src: url('../fonts/27BDE8_1_0.eot');
    src: url('../fonts/27BDE8_1_0.eot?#iefix') format("embedded-opentype"),
         url('../fonts/27BDE8_1_0.woff') format("woff"),
         url('../fonts/27BDE8_1_0.ttf') format("truetype");
    font-weight: normal;
    font-style: normal
}


body {
    min-width: 920px;
    font: 15px/1.5 'proxima nova', sans-serif;
    background-color: #f7f8f8;
    color: #4f4e4e;
    -webkit-print-color-adjust: exact;
}

/* Links */
a {
    font-family: 'proxima nova bold', sans-serif;
    font-weight: normal;
    text-decoration: none;
    color: #ef5f0b;
}

a:visited {
    color: #ef5f0b;
    opacity: 0.8;
}

a:hover {
    text-decoration: underline;
}

b, strong {
    font-family: 'proxima nova bold', sans-serif;
    font-weight: normal;
    color: #000;
}

.strict {
    width: 920px;
    margin: 0 auto;
}

header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 7;
    padding: 5px 0;
    background: #000;
    height: 25px;
    color: #aaa9aa;
}

.header-logo {
    display: inline-block;
    width: 212px;
    height: 47px;
    margin-top: 10px;
    background: url(../images/logolockup_small.png) no-repeat 50% 50%;
     -webkit-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out;
}

ul.top {
    list-style-type: none;
    position: relative;
    text-align: right;
    vertical-align: baseline;
    line-height: 20px;
}

ul.top li {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
}

/* Header User Area */
ul.top .btn-return {
    width: auto;
    text-align: center;
    padding: 0 20px;

    display: inline-block;
    vertical-align: middle;
    background: url(../images/icon-location.png) 0 50% no-repeat;
}

ul.top .btn-return a {
    margin-left: 10px;
    color: #fff;

}

/* Header logo */
ul.top .logotype {
    position: absolute;
    left: 0;
    top: 0;

    -webkit-transform: translateX(0);
            transform: translateX(0);

    -webkit-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out;
}

ul.top .logotype a {
    display: block;
    width: 46px;
    height: 55px;
    background: url('../images/logolockup_small.png') no-repeat;
    background-size: contain;
}


.menu-link {
    color: #00b188;
    font-family: 'proxima nova bold', sans-serif;
}

.menu-link:hover {
    color: #00b188;
}


/* Subheader */
.subheader {
    height: 80px;
    position: fixed;
    left: 0;
    right: 0;
    top: 35px;
    z-index: 2;
    background-color: #f7f8f8;
}

.subheader .strict {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}

.subheader-logo {
    display: inline-block;
    margin-top: 0;
    background: url(../images/logolockup_small.png) no-repeat 0 0;
    width: 300px;
    height: 77px;
}

.subheader-logo-elderpla {
    background: url(../images/logo-elderpla.png) no-repeat 0 5px;
    display: inline-block;
    margin-top: 0;
    width: 300px;
    height: 77px;
}

.subheader .navigation a {
    margin-right: 20px;
}
/* Content */
.main-content {
    padding-top: 135px;
    padding-bottom: 110px;
    -webkit-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out;
}

.main-content-map {
    padding-top: 55px;
    padding-bottom: 0px;
    -webkit-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.autocomplete,
.autocomplete-mobile{
    position: absolute;
    background-color: #fff;
    box-shadow: 0 5px 30px -5px rgba(0,0,0, 0.4);
    width: 100%;
    padding: 20px 0;
    z-index: 1;
}

.autocomplete:empty,
.autocomplete-mobile:empty {
    padding: 0;
}

.autocomplete a,
.autocomplete-mobile a {
    color: #565656;
    font-size: 13px;
    display: block;
    padding: 5px 20px;
}

.subheader-search input[type=text] {
    transition: all 0.3s ease-in;
}

.subheader-search input[type=text]:focus {
    width: 300px;
}



.subheader-search-input {
    display: inline-block;
    position: relative;
}

/* Footer */
footer {
    padding: 25px 0;
    position: fixed;
    left: 0;
    /*right: 0;*/
    width: 100%;
    bottom: 0;
    font-size: 14px;
    background-color: #4f4e4e;
    color: #fff;
    -webkit-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out;
}

footer p {
    margin-bottom: 1.5em;
    font-size: 11px;
}

footer p:last-child {
    margin-bottom: 0;
}

.footer-links a {
    margin: 5px 15px 5px 0;
    font: 14px 'proxima nova bold', sans-serif;
    text-decoration: none;
    border-bottom: 1px solid rgba(255,255,255, 0.3);
    color: #fff;
    display: inline-block;
}

.footer-links a:hover {
    border-bottom-color: #fff;
}

.footer-logo {
    width: 82px;
    margin-bottom: 2rem;
}

.mobile-show {
    display: none !important;
}

.ember-view:not(.barcode) {
    min-height: 100%;
    position: relative;
}

.ember-view.overlay {
    min-height: 0;
}

/*  account */
.sidebar {
    width: 200px;
    float: left;
}

.sidebar-header {
    font:  16px 'museo slab bold', sans-serif;
}

.right-column {
    min-height: 300px;
    margin-left: 200px;
    padding-left: 30px;
    border-left: 2px solid #eee;
}

.account-separator {
    height: 0;
    border-bottom: 2px solid #eee;
    margin-left: -30px;
}

.sidebar-list {
    margin: 10px 0 20px;
}

.sidebar-list a {
    display: block;
    position: relative;
    padding: 5px 0 5px 18px;
    font: 15px 'proxima nova', sans-serif;
}

.sidebar-list a.active {
    color: #aaa9aa;
}

.sidebar-list a.active:before {
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    left: -8px;
    top: 1px;
    /*background: url(../images/arrow-list.png) 0 0 no-repeat;*/
    background: url(../images/arrow-list.svg) 0 0 no-repeat;
    transform: rotate(-90deg);
}

.search-container input[type="search"],
.subheader-search input[type="text"] {
    border: 1px solid #c5c5c6;
    background-color: transparent;
    border-bottom: 1px solid #f1f1f1;
    width: 172px;
    height: 30px;
    padding: 0 8px;
    outline: none;
}

.zip-filter-input {
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 3px;
    padding-left: 24px !important;
}

.search-container input[type="text"]:focus,
.subheader-search input[type="text"]:focus {
    border-color: #aaa9aa;
    box-shadow: 0 0 4px rgba(0,0,0,.2);
}

.subheader-search input[type="submit"] {
    border: 0;
    background-color: transparent;
    color: #03af88;
    cursor: pointer;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .subheader-logo {
        background-image: url(../images/logolockup_large.png);
        background-size: 212px 47px;
    }

    .header-logo {
        background-image: url(../images/logolockup_large.png);
        background-size: 212px 47px;
    }
}

.show-for-mobile {
    display: none;
}
