.auth-box {
    position: relative;
    width: 270px;
    margin: auto;
}

.auth-box .form-field {
    padding-right: 0;
}

.auth-separator {
    display: block;
    position: absolute;
    height: 240px;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 0;
    border-left: 1px solid #d6d6d6;
    font: 14px 'proxima nova bold', sans-serif;
}

.auth-separator span {
    display: inline-block;
    height: 55px;
    line-height: 55px;
    position: absolute;
    top: 50%;
    left: -10px;
    margin-top: -28px;
    background-color: #fff;
}

.social-buttons {
    display: none;
}

.auth-form-terms {
    width: 555px;
    margin-bottom: 40px;
}

.auth-form-terms-text {
    height: 180px;
    overflow: auto;
    margin-bottom: 25px;
    padding: 14px 12px;
    border: 1px solid #c5c5c5;
    border-bottom: rgba(0,0,0, 0.1);
    background-color: #f8f8f8;
}

.auth-form-terms-agree input {
    margin: 0 5px 0 0;
}

.help-tip{
    position: absolute;
    top: -4px;
    text-align: center;
    background-color: #ed5e0b;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    font-size: 14px;
    line-height: 26px;
    cursor: default;
}

.help-tip.login {
    right: 190px;
}

.help-tip.signup-form {
    left: 50px;
}

.help-tip.biller-form {
    right: 195px;
}

.help-tip.confirmation {
    left: 185px;
}

.help-tip.confirmation p {
    width: 350px;
}

.help-tip:before{
    content:'?';
    font-weight: bold;
    color:#fff;
}

.help-tip:hover p{
    display:block;
    transform-origin: 100% 0%;

    -webkit-animation: fadeIn 0.3s ease-in-out;
    animation: fadeIn 0.3s ease-in-out;
    z-index: 999;
}

.help-tip p{    /* The tooltip */
    display: none;
    text-align: left;
    background-color: #CDEEEC;
    padding: 20px;
    width: 400px;
    position: absolute;
    border-radius: 3px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    left: 40px;
    top: -10px;
    color: #000;
    font-size: 13px;
    line-height: 1.4;
}

.help-tip p:before{ /* The pointer of the tooltip */
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-right-color: #CDEEEC;
    left: -20px;
    top: 10px;
}

.help-tip p:after{ /* Prevents the tooltip from being hidden */
    width:100%;
    height:40px;
    content:'';
    position: absolute;
    top:-40px;
    left:0;
}

/* CSS animation */

@-webkit-keyframes fadeIn {
    0% { 
        opacity:0; 
        transform: scale(0.6);
    }

    100% {
        opacity:100%;
        transform: scale(1);
    }
}

@keyframes fadeIn {
    0% { opacity:0; }
    100% { opacity:100%; }
}
