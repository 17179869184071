.popular-billers h2 {
    margin: 0 0 20px;
    text-align: left;
    border: 0;
    font: normal 13px "museo slab bold", sans-serif;
    color: #4f4e4e;
}

.popular-billers .list {
    display: flex;
    justify-content: space-between;
}

.popular-billers--inner {
    height: 100%;
    display: flex; 
    flex-direction: column; 
    justify-content: space-between;
} 

.popular-billers--inner .thumb {
    margin-bottom: auto;
}

.popular-billers li {
    text-align: center;
    display: inline-block;
    vertical-align: top;
    width: 238px;
    margin-bottom: 30px;
}

.popular-billers li .box {
    background: #fff;
    margin-bottom: 30px;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0,0,0, 0.2);
}

.popular-billers li .thumb {
    padding-bottom: 10px;
    vertical-align: middle;
    position: relative;
    font-family: 'museo slab bold', sans-serif;
}

.popular-billers li .thumb::before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.popular-billers li .thumb span,
.popular-billers li .thumb img {
    display: inline-block;
    vertical-align: middle;
    text-transform: uppercase;
    font-size: 20px;
    max-width: 210px;
}

.popular-billers li label {
    font-weight: normal;
}

