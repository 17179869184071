h1 {
    font: 36px 'proxima nova', sans-serif;
}

h2 {
    font: 28px 'proxima nova', sans-serif;
}

h3 {
    font: 20px 'proxima nova', sans-serif;
}

h4 {
    font: 20px 'proxima nova', sans-serif;
}

h5 {
    font: 16px 'proxima nova', sans-serif;
}

.right-column h1 {
    padding-bottom: 20px;
    color: #abaaab;
    font: normal 40px 'museo slab', sans-serif;
}

.right-column hr {
    border-bottom: 2px solid #f2f1f0;
    margin: 10px 0;
}

.right-column h2 {
    padding-top: 20px;
    font: 20px 'museo slab bold', sans-serif;
    color: #748686;
}
